import React, { useState, useEffect, useRef } from 'react';
import './Testimonial.css';

const testimonials = [
  {
    name: 'Francesca G',
    picture: '/static/images/profile7.jpeg',
    review: `Tutti helper is a game changer for the vacation rental industry. It's the first time that I felt relaxed in a vacation rental.`,
    location: 'Lisbon',
  },
  {
    name: 'Michael C',
    picture: '/static/images/profile1.jpeg',
    review: 'Adriana was so helpful and responsible. She appeared to be very experienced. I booked her throughout my entire vacation at my Airbnb. My kids adore her.',
    location: 'Montreal',
  },
  {
    name: 'Lia B',
    picture: '/static/images/profile4.jpeg',
    review: `Tutti Helper is the best thing you can book on Tutti! It's the first time that I've truly enjoyed and relaxed while being in a vacation rental.`,
    location: 'San Francisco',
  },
  {
    name: 'Yifat E',
    picture: '/static/images/profile5.jpeg',
    review: 'Adriana is Tutti - all in one, babysit my kids, grocery delivery, booked us few activities, she even unpacked and packed our suitcases.',
    location: 'Tel Aviv',
  },
  {
    name: 'Ron I',
    picture: '/static/images/profile6.jpeg',
    review: 'Molly was amazing, we booked 5 lessons with her for our 9-year-old son. It was a super experience, and his surfing really improved while we felt safe knowing that Molly was there with him.',
    location: 'Maui',
  },
  {
    name: 'Yonit A',
    picture: '/static/images/profile8.jpg',
    review: 'Charleston was amazing! She was on-time, enthusiastic, and sweet with the kids. We felt very comfortable leaving our kids with her and will absolutely use this service again.',
    location: 'Las Vegas',
  },
  {
    name: 'Steven S',
    picture: '/static/images/profile.jpg',
    review: 'Great service and experience. The rented things were in great shape and very clean.',
    location: 'Rome',
  },
  {
    name: 'Carolina C',
    picture: '/static/images/profile9.jpeg',
    review: `Sailor is an amazing baker, communicated well and was very professional. I highly recommend booking her!`,
    location: 'New York',
  },
  {
    name: 'Lani Paradise',
    picture: '/static/images/profile2.jpeg',
    review: 'Samson was a pleasure to work with! He delivered coconuts to my guests at my vacation rental and they were so happy! I’d highly recommend booking Samson!',
    location: 'Maui',
  },
];

const Testimonial = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isMobile, setIsMobile] = useState(false);
  const intervalRef = useRef(null);

  const startAutoSlide = () => {
    intervalRef.current = setInterval(() => {
      nextSlide();
    }, 20000);
  };

  const stopAutoSlide = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
  };

  const nextSlide = () => {
    if (isMobile) {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
    } else {
      setCurrentIndex((prevIndex) => (prevIndex + 3) % testimonials.length);
    }
  };

  const prevSlide = () => {
    if (isMobile) {
      setCurrentIndex((prevIndex) =>
        prevIndex === 0 ? testimonials.length - 1 : prevIndex - 1
      );
    } else {
      setCurrentIndex((prevIndex) =>
        prevIndex === 0 ? testimonials.length - 3 : prevIndex - 3
      );
    }
  };

  const handleNextClick = () => {
    stopAutoSlide();
    nextSlide();
    startAutoSlide();
  };

  const handlePrevClick = () => {
    stopAutoSlide();
    prevSlide();
    startAutoSlide();
  };

  useEffect(() => {
    const mediaQueryList = window.matchMedia('(max-width: 767px)');
    setIsMobile(mediaQueryList.matches);

    function updateIsMobile(e) {
      setIsMobile(e.matches);
    }
    mediaQueryList.addEventListener('change', updateIsMobile);

    return function cleanup() {
      mediaQueryList.removeEventListener('change', updateIsMobile);
    };
  });

  useEffect(() => {
    startAutoSlide();
    return () => stopAutoSlide();
  }, []);

  const displayedTestimonials = isMobile
    ? [testimonials[currentIndex]]
    : [
        testimonials[currentIndex],
        testimonials[(currentIndex + 1) % testimonials.length],
        testimonials[(currentIndex + 2) % testimonials.length],
      ];

  return (
    <div className="testimonial-container">
      <button className="arrow left-arrow" onClick={handlePrevClick}>
        &#10094;
      </button>
      <div className="testimonial-carousel">
        {displayedTestimonials.map((testimonial, index) => (
          <div className="testimonial-item" key={index}>
            <div className="testimonial-header">
              <img
                src={testimonial.picture}
                alt={`${testimonial.name}'s profile`}
                className="testimonial-picture"
              />
            </div>
            <div className="testimonial-stars">
              {'⭐️⭐️⭐️⭐️⭐️'}
            </div>
            <div className="testimonial-content">
              <p className="testimonial-review">{testimonial.review}</p>
            </div>
            <div className="testimonial-footer">
              <p className="testimonial-name">{testimonial.name}</p>
              <p className="testimonial-location">- {testimonial.location}</p>
            </div>
          </div>
        ))}
      </div>
      <button className="arrow right-arrow" onClick={handleNextClick}>
        &#10095;
      </button>
    </div>
  );
};

export default Testimonial;
