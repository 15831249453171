import React from 'react';
import './BusinessLinks.css';

const businesses = [
  { name: "Makoa Resorts", url: "https://makoaresorts.com", logo: "/static/images/logo15.png" },
  { name: "Lani Paradise", url: "https://airbnb.com/h/lanisoceanfrontretreat", logo: "/static/images/logo5.jpeg" },
  { name: "Maui By the Sea", url: "https://mauibythesea.com", logo: "/static/images/logo1.jpg" },
  { name: "Lizya’s Luxury Vacations", url: "https://www.airbnb.com/rooms/53384200?check_in=2024-11-13&check_out=2024-11-18&guests=1&adults=6&s=2&unique_share_id=fefffbc5-41ac-4613-b9db-e1e695c0a34c", logo: "/static/images/logo4.png" },
  { name: "Paia Inn", url: "https://paiainn.com", logo: "/static/images/logo12.jpg" },
  { name: "Makoa Car Rentals", url: "https://carrentalmaui.com", logo: "/static/images/logo6.png" },
  { name: "Kimos Rent A Car", url: "https://kimosrentacar.com", logo: "/static/images/logo10.jpg" },
  { name: "Lumeria Maui", url: "https://lumeriamaui.com", logo: "/static/images/logo3.jpg" },
];

const BusinessLinks = () => {
  return (
    <div className="business-links">
      {businesses.map((business, index) => (
        <a key={index} href={business.url} target="_blank" rel="noopener noreferrer" className="business-link">
          <img src={business.logo} alt={business.name} className="business-logo" />
        </a>
      ))}
    </div>
  );
};

export default BusinessLinks;
